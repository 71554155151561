import React from "react";

export default function A4() {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="A4">
        <g id="Group">
          <path
            id="Vector"
            d="M429.105 150.395C429.123 150.377 294.123 15.377 294.123 15.377C284.599 5.888 271.474 0 257 0H75C70.857 0 67.5 3.357 67.5 7.5V241C67.5 245.143 70.857 248.5 75 248.5C79.143 248.5 82.5 245.143 82.5 241V15H257C277.678 15 294.5 31.822 294.5 52.5V142.5C294.5 146.643 297.857 150 302 150H392C412.678 150 429.5 166.822 429.5 187.5V497H82.5V271C82.5 266.857 79.143 263.5 75 263.5C70.857 263.5 67.5 266.857 67.5 271V504.5C67.5 508.643 70.857 512 75 512H437C441.143 512 444.5 508.643 444.5 504.5V187.5C444.5 173.026 438.612 159.901 429.105 150.395V150.395ZM309.5 135V52.5C309.5 52.317 309.488 52.137 309.486 51.955L392.545 135.014C392.363 135.012 392.183 135 392 135H309.5Z"
            fill="#242424"
          />
        </g>
        <path
          id="A4_2"
          d="M223.771 326.035H161.222L147.174 365H126.871L183.883 215.703H201.109L258.224 365H238.023L223.771 326.035ZM167.169 309.834H217.926L192.496 240.005L167.169 309.834ZM353.482 314.858H374.195V330.342H353.482V365H334.41V330.342H266.427V319.165L333.282 215.703H353.482V314.858ZM287.96 314.858H334.41V241.646L332.154 245.747L287.96 314.858Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
