import React from "react";

export default function A3A4() {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="A3_A4">
        <g id="Group">
          <path
            id="Vector"
            d="M429.105 150.395C429.123 150.377 294.123 15.377 294.123 15.377C284.599 5.888 271.474 0 257 0H75C70.857 0 67.5 3.357 67.5 7.5V241C67.5 245.143 70.857 248.5 75 248.5C79.143 248.5 82.5 245.143 82.5 241V15H257C277.678 15 294.5 31.822 294.5 52.5V142.5C294.5 146.643 297.857 150 302 150H392C412.678 150 429.5 166.822 429.5 187.5V497H82.5V271C82.5 266.857 79.143 263.5 75 263.5C70.857 263.5 67.5 266.857 67.5 271V504.5C67.5 508.643 70.857 512 75 512H437C441.143 512 444.5 508.643 444.5 504.5V187.5C444.5 173.026 438.612 159.901 429.105 150.395V150.395ZM309.5 135V52.5C309.5 52.317 309.488 52.137 309.486 51.955L392.545 135.014C392.363 135.012 392.183 135 392 135H309.5Z"
            fill="#242424"
          />
        </g>
        <path
          id="A3"
          d="M173.885 204.518H142.611L135.587 224H125.436L153.941 149.352H162.555L191.112 224H181.012L173.885 204.518ZM145.584 196.417H170.963L158.248 161.502L145.584 196.417ZM212.491 182.062H219.618C224.095 181.993 227.616 180.814 230.179 178.524C232.743 176.234 234.024 173.141 234.024 169.244C234.024 160.494 229.667 156.119 220.951 156.119C216.849 156.119 213.568 157.298 211.107 159.657C208.68 161.981 207.467 165.074 207.467 168.937H197.982C197.982 163.023 200.135 158.119 204.442 154.222C208.783 150.292 214.286 148.326 220.951 148.326C227.992 148.326 233.512 150.189 237.511 153.915C241.51 157.64 243.509 162.818 243.509 169.449C243.509 172.696 242.45 175.841 240.331 178.883C238.246 181.925 235.392 184.198 231.769 185.702C235.87 187 239.032 189.154 241.253 192.162C243.509 195.169 244.637 198.844 244.637 203.185C244.637 209.884 242.45 215.199 238.075 219.129C233.7 223.06 228.009 225.025 221.002 225.025C213.995 225.025 208.287 223.128 203.878 219.334C199.503 215.541 197.315 210.533 197.315 204.312H206.852C206.852 208.243 208.133 211.388 210.697 213.746C213.26 216.104 216.695 217.284 221.002 217.284C225.582 217.284 229.085 216.087 231.512 213.695C233.939 211.302 235.152 207.867 235.152 203.39C235.152 199.049 233.819 195.716 231.153 193.392C228.487 191.068 224.642 189.872 219.618 189.803H212.491V182.062Z"
          fill="black"
        />
        <path
          id="&#38;"
          d="M174.589 301.977C174.589 300.182 175.085 298.533 176.076 297.029C177.084 295.525 178.998 293.774 181.818 291.774C180.16 289.758 179.05 288.134 178.486 286.904C177.922 285.673 177.64 284.451 177.64 283.238C177.64 280.401 178.477 278.179 180.152 276.573C181.827 274.966 184.1 274.163 186.971 274.163C189.534 274.163 191.653 274.915 193.328 276.419C195.003 277.906 195.84 279.786 195.84 282.059C195.84 283.562 195.456 284.955 194.687 286.237C193.935 287.502 192.61 288.835 190.713 290.236L187.97 292.261L196.276 302.182C197.438 299.96 198.019 297.491 198.019 294.773H202.3C202.3 299.131 201.249 302.746 199.147 305.617L204.479 312H198.788L196.302 309.052C195.037 310.18 193.55 311.043 191.841 311.641C190.149 312.222 188.423 312.513 186.663 312.513C183.023 312.513 180.101 311.547 177.896 309.616C175.691 307.685 174.589 305.138 174.589 301.977ZM186.663 308.642C189.175 308.642 191.474 307.685 193.559 305.771L184.458 294.876L183.613 295.491C180.759 297.593 179.332 299.755 179.332 301.977C179.332 303.993 179.981 305.608 181.28 306.822C182.596 308.035 184.39 308.642 186.663 308.642ZM182.382 283.084C182.382 284.725 183.39 286.775 185.407 289.236L188.432 287.109C189.594 286.288 190.389 285.511 190.816 284.776C191.243 284.024 191.457 283.118 191.457 282.059C191.457 280.914 191.03 279.965 190.175 279.213C189.321 278.444 188.244 278.06 186.945 278.06C185.544 278.06 184.433 278.538 183.613 279.495C182.792 280.435 182.382 281.631 182.382 283.084Z"
          fill="black"
        />
        <path
          id="A4"
          d="M174.885 415.518H143.611L136.587 435H126.436L154.941 360.352H163.555L192.112 435H182.012L174.885 415.518ZM146.584 407.417H171.963L159.248 372.502L146.584 407.417ZM239.741 409.929H250.098V417.671H239.741V435H230.205V417.671H196.213V412.083L229.641 360.352H239.741V409.929ZM206.98 409.929H230.205V373.323L229.077 375.374L206.98 409.929Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
