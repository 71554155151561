import React, { useContext } from "react";
import { QuestionSection, Radio } from "../../components/utility/quote";
import { navigate } from "gatsby-link";
import A3A4 from "../../svgs/printer/A3_A4";
import A4 from "../../svgs/printer/A4";
import CopierContext from "../../contexts/copierContext";

const Next = "/copier-printer/Questions/3";
const Points = [
  {
    question: "What paper sizes do you need to print?",
    progress: 50,
    options: [
      {
        answer: "A3 & A4",
        to: Next,
        icon: <A3A4 />,
      },
      {
        answer: "A4 only",
        to: Next,
        icon: <A4 />,
      },
    ],
  },
];

export default function Question2({ title }) {
  const { que2, setQue2 } = useContext(CopierContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <Radio
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que2 === items.answer}
              onChange={(e) => {
                setQue2(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
